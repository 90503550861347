<template>
  <div class="drawer">
    <div class="drawer-header" :style="{ background: headBgc, marginTop: '0px' }">
      <div v-if="selectedNode.type === 'CONDITION' || selectedNode.type === 'APPROVAL'">
        {{ selectedNode.name }}
      </div>
      <div v-else>设置归档基础信息</div>
      <span v-if="selectedNode.type === 'CONDITION'" class="level">{{ level }}</span>
    </div>
    <div class="main">
      <BranchConfig
        v-if="selectedNode.type === 'CONDITION'"
        :config="selectedNode.props"
        :showConfig="showConfig"
        :isCancel="isCancel"
      ></BranchConfig>
      <!-- 开始或结束 或审批 -->
      <AppConfig
        v-else-if="selectedNode.type === 'APPROVAL'"
        :config="selectedNode.props"
        :showConfig="showConfig"
        :isCancel="isCancel"
        @handleOk="handleOk"
      />
      <CcConfig
        v-else
        :config="selectedNode.props"
        :showConfig="showConfig"
        :isCancel="isCancel"
        @handleOk="handleOk"
      />
    </div>
    <div v-if="!allDisabled" class="footer" :style="{ width: '444px' }">
      <a-button @click="cancel" class="MyCancel">取消</a-button>
      <a-button type="primary" @click="handleOk" class="footer-ok">保存</a-button>
    </div>
  </div>
</template>

<script>
import BranchConfig from './BranchConfig.vue'
import CcConfig from './CcConfig.vue'
import AppConfig from './AppConfig.vue'

export default {
  props: {
    headBgc: {
      type: String,
      defaulte: ''
    },
    showConfig: {
      type: Boolean,
      defaulte: false
    }
  },
  components: {
    CcConfig,
    BranchConfig,
    AppConfig
  },
  watch: {
    showConfig: {
      deep: true,
      handler(value) {
        // console.log(value)
        if (value && this.isCancel) {
          this.isCancel = false
        }
      }
    }
  },
  data() {
    return {
      // selectedNode: {},
      isCancel: false,
      formData: {}, // 发起/审批
      branData: [] // 条件分支
    }
  },
  computed: {
    level() {
      return this.$store.state.process.selectedNode.name.replace('条件', '优先级', 1)
    },
    selectedNode() {
      return this.$store.state.process.selectedNode
    },
    allDisabled() {
      return this.$store.state.process.isPreview
    }
  },
  created() {
    console.log('selectedNode', this.selectedNode)
  },
  methods: {
    cancel() {
      this.$emit('cancel', false)
      console.log(this.$store.state.process.selectedNode)
    },
    handleOk(value) {
      // 如果是审批或者发起结束节点需要校验是否添加了人员
      // console.log(283727777, value);
      // let isOpen = false
      // if(value === 'warning') {
      //   isOpen = true
      // }
      this.$emit('ok', false)
      this.isCancel = true
    }
  }
}
</script>

<style scoped lang="less">
.drawer {
  position: relative;
  .drawer-header {
    width: 100%;
    padding: 14px 24px;
    color: #fff;
    position: fixed;
    margin-top: -60px;
    z-index: 1000;
    display: flex;
    .level {
      background: rgba(255, 255, 255, 0.3);
      font-size: 12px;
      padding: 3px 9px;
      border-radius: 4px 4px 4px 0px;
      margin-left: 8px;
    }
  }
  .main {
    padding: 24px;
    padding-top: 90px;
    margin-bottom: 120px;
    .ant-form-item {
      margin-bottom: 32px;
    }
  }
  .footer {
    position: fixed;
    bottom: 0px;
    border-top: 1px solid #ebedf0;
    background-color: #fff;
    padding: 10px 24px;
    text-align: right;
    .footer-ok {
      margin-left: 8px;
    }
  }
  :deep(.ant-radio-checked .ant-radio-inner:after) {
    transform: none;
  }
  :deep(.ant-radio-inner:after) {
    top: 50%;
    left: 50%;
    transform: scale(1) translate(-50%, -50%) !important;
  }
}
</style>
